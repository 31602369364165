(function ($) {

  // ---------- APP START
  window.App = window.App || {

    config: {
      loaderMarkup: '<div class="spinner-square"></div>',
      scrollOffset: 0,
      mapStyles: []
    },

    $cursor: null,
    $animated: null,
    $linkClicked: null,
    isMobile: false,
    isHome: false,
    closeNav: false,
    lastMouseMoveEvent: null,
    transitioning: false,

    initOnce: function() {
    },

    initPage: function () {
      $.fn.matchHeight._maintainScroll = true;
      App.$cursor = null;
      App.$linkClicked = null;
      App.isMobile = $(window).width() <= 1024;
      App.isHome = (location.pathname == '/');
      App.closeNav = (!App.isHome && !$('.page').is('.error404')) || App.isMobile;

      // Reinject SiteOrigin's styles in head to keep them between pages
      var $so_styles = $('.page').find('.siteorigin-panels-layouts-head').detach();
      if ($so_styles.length && $('head').find('#' + $so_styles.attr('id')).length == 0) {
        $so_styles.appendTo('head');
      } else {
        $so_styles.remove();
      }

      // Init SVG polyfill
      if (typeof (svg4everybody) !== 'undefined') svg4everybody();

      // Init object-fill polyfill
      if (typeof (objectFitImages) !== 'undefined') objectFitImages();

      // Remove focus state when clicking on a button with a mouse
      $(':not(form) button, a').on('click', function (e) {
        if (e.clientX && e.clientY)
          $(this).blur();
      });

      $('a[href^="#"]').on('click.smooth', App.scrollHandler);

      if (App.isHome) {
        $('a[href^="/#"]').on('click.smooth', App.scrollHandler);
      }

      if ($('[data-scroll]').length) {
        App.scroll($('[data-scroll]').data('scroll'));
      }

      if ($('.home').length) App.initHome();

      $('[data-mh]').matchHeight();
      if ($('.features-section').length) App.initFeatures();
      if ($('.btn-file').length) App.initButtonFile();
      if ($('.menu_burger').length) App.initMenuBurger();
      if (!App.isMobile && $('#cursor').length) App.initCursor();
      if ($('.page__content').length) App.initAnimateContent();
      if ($('.header__scrolldown').length) App.initScrollDown();
      if ($('.wpcf7-form').length) App.iniTrackingForms();

      enquire.register('screen and (min-width: 64.0625em)', {
        match: function () {
          var hash = location.hash.substr(1) || "";
          if (hash == '') $('.home').addClass('animate');
          if($('.home').length) {
            setTimeout(function () {
              $('.horiz-menu').find('section').horizon({
                swipe: false,
                start: hash,
                fnCallback: function (i) {
                  $('.menu__main').find('.current').removeClass('current');
                  var section = $.fn.horizon.defaults.sections[i];
                  if (section !== undefined) {
                    $('.menu__main').find("a[data-section=" + section.id + "]").addClass('current');
                    if ($('#page').hasClass('home')) history.replaceState(undefined, document.title, section.id == 'intro' ? '/' : '#' + section.id);

                    if (App.isHome) {
                      $('.header__logo svg').css('fill', (section.id == 'agency') ? '#5587f7' : '#fff');
                      App.$cursor.attr('color', (section.id == 'agency') ? 'blue' : 'white');
                      App.gtag_view('/menu-'+section.id);
                    }
                  }
                }
              });
            }, 200);

            var toMenuHover;
            $('.home .menu__main a, .home .header__logo a').off('click.smooth').hover(function () {
              var section = $(this).data('section');
              toMenuHover = setTimeout(function () {
                $.fn.horizon('scrollTo', section);
              }, 200);
            }, function () {
              clearTimeout(toMenuHover);
            }).click(function (e) {
              e.preventDefault();
              var section = $(this).data('section');
              $.fn.horizon('scrollTo', section);
            });
          }
        },
        unmatch: function () {
          $(document).off('.hrznscrll');
          $(window).off('.hrznscrll');
        }
      });

      $('.page').mousemove(function(e){App.lastMouseMoveEvent = e;});
    },

    transitionCompleted: function() {
      App.animateContent();
      $('footer').toggleClass('nav-opened', !App.closeNav);
      App.transitioning = false;

      if(window.location.pathname === "/") {
        $('body').addClass('homepage');
        window.dispatchEvent(new Event('resize'));
      } else
        $('body').removeClass('homepage');

      App.gtag_view(window.location.pathname);
    },

    quitPage: function () {
      enquire.unregister('screen and (min-width: 48em)');
      App.transitioning = true;
    },

    load: function () {
      if (window.location.hash && $(window.location.hash).length) App.scroll(window.location.hash, 150);
    },

    scrollHandler: function(e) {
      e.preventDefault();

      var scrollTo = $(this).data('scroll-to') ? $(this).data('scroll-to') : $(this).attr('href').replace(/^\//, '');

      if ($(scrollTo).length > 0 && $(this).data('prevent-scroll') !== true) {
        $('footer').removeClass('nav-opened');
        App.scroll(scrollTo, $(this).data('scroll-offset'));
      }
    },

    scroll: function (element, offset) {
      offset = offset === undefined ? App.config.scrollOffset : offset;
      $(element).velocity('scroll', {easing: 'slow', offset: offset});
      history.pushState({}, '', element);
    },

    initHome: function() {
      if($(window).width() > 768) {
        $('.section__metiers, .section__realisations').each(function(){
          var $section = $(this);
          var $bg = $('<div class="section__background">').prependTo($section);
          $section.find('.menu__link[data-bg-src]').hover(
            function(){
              if (!App.transitioning) {
                var bg_src = $(this).data('bg-src');
                var $bg_div = $('<div class="section__background-img" style="background-image: url(' + bg_src + ');">').appendTo($bg);
                $bg_div.velocity({opacity: 1}, {easing: 'ease', duration: 400});
                $(this).data('bg-div', $bg_div);
              }
            },
            function() {
              if (!App.transitioning) {
                var $bg_div = $(this).data('bg-div');
                $bg_div.velocity({opacity: 0}, {easing: 'ease', delay: 200, duration: 300, complete: function(){$bg_div.remove();}});
              }
            });
        });
      }
    },

    initMenuBurger: function() {
      if (App.closeNav) {
        if (App.isMobile) {
          $('.menu_burger').on('click', function(e) {
            $('footer').addClass('nav-opened');
          });

          $('.menu__close').on('click', function(e) {
            $('footer').removeClass('nav-opened');
          });
        } else {
          $('.menu_burger').on('mouseover click', function(e) {
            $('footer').addClass('nav-opened');
          });
          $('main').on('mouseenter', function(e) {
            $('footer').removeClass('nav-opened');
          });
        }
      }
    },

    initScrollDown: function() {
      var $scrolldown = $('.header__scrolldown');

      if(!App.isMobile && ($(window).height() / $(document).outerHeight()) < 0.8) {
        var width = $scrolldown.find('span').attr('style', null).outerWidth();
        var contentY = $('.page__head-container').outerHeight() - $scrolldown.position().top;

        $(window).scroll(function(){
          var scrollY = $(window).scrollTop();

          $scrolldown.toggleClass('dark', scrollY > contentY);

          if(scrollY < 42) {
            $scrolldown.removeClass('spying');
            $scrolldown.find('span').attr('style', null);
          } else {
            var progression = scrollY / ($(document).outerHeight() - $(window).height());
            $scrolldown.addClass('spying');
            $scrolldown.find('span').css({width: width*progression});
          }
        });
      } else {
        $scrolldown.hide();
      }
    },

    iniTrackingForms: function() {
      document.addEventListener( 'wpcf7mailsent', function( event ) {
        switch (event.detail.contactFormId) {
          case "312":
            App.gtag_view('/candidature-spontanee-OK');
            break;
          case "313":
            App.gtag_view('/reponse-offre-rh-OK');
            break;
          case "124":
            App.gtag_view('/contact-generique-OK');
            break;
        }
      });
    },

    initCursor: function() {
      App.$cursor = $('#cursor');

      App.$cursor.parent()
        .on('mouseover', function(e) {
          App.$cursor.css({visibility: 'visible'});
        })
        .on('mouseout', function(e) {
          App.$cursor.css({visibility: 'hidden'});
        })
        .on('mousemove', function(e) {
          var $target = $(e.target);

          App.$cursor.css({
            'top' : (e.pageY-24) + 'px',
            'left' : (e.pageX-24) + 'px'
          });

          if(App.isHome) {
            var index = $.fn.horizon.defaults.i;
            var $link = $target.is('a') ? $target : $target.parents('a');
            var state = 'pointer';
            if ($link.length == 0) {
              state = (e.pageX < ($(window).width()/2)) ? 'left' : 'right';
              if (index <= 0) state = 'right';
              if (index >= $.fn.horizon.defaults.limit-1) state = 'left';
            }
            if (App.$cursor.attr('state') != state) {
              App.$cursor.attr('state', state);
            }
          }
        })
        .on('click', function(e){
          var $target = $(e.target);
          if (!$target.is('a') && $target.parents('a').length == 0) {
            if (App.$cursor.attr('state') == 'left') {
              $.fn.horizon('scrollLeft');
            } else {
              $.fn.horizon('scrollRight');
            }
          }
          App.$cursor.parent().trigger(App.lastMouseMoveEvent);
        })
        .addClass('css-cursor');

        if(App.lastMouseMoveEvent) App.$cursor.parent().trigger(App.lastMouseMoveEvent);
    },

    initAnimateContent: function() {
      var $container = $('.page__content');
      App.$animated = $container.find('h2,h3,p,ul,.keypoint,.sow-image-container').addClass('animated');

      // var left = $container.offset().left;
      // var middle = $container.outerWidth() / 2;
      // if(($el.offset().left - left) < middle) {
      //   $el.addClass('right');
      // } else {
      //   $el.addClass('left');
      // }
    },

    animateContent: function() {
      if(App.$animated) {
        App.$animated.each(function(){
          $el = $(this);
          if($el.is('.sow-image-container')) {
            $el.addClass('zoom-out');
          } else if($el.is('.overlap__overlapping ul')) {
            $el.addClass('left');
          } else {
            $el.addClass('up');
          }
        });

        App.$animated.waypoint({
          handler: function() {
            $(this.element).addClass('animate');
          },
          offset: function() {
            return ($(window).height()*1.1) - (this.element.clientHeight / 2);
          }
        });
      }
    },

    initButtonFile: function() {
      $('.btn-file').each(function() {
        var $btn = $(this);
        var $span = $btn.find('span');
        var originalLabel = $span.text();

        var $input = $('#'+$btn.attr('for'));
        $input.on('change', function() {
          var fileName = $input.val().split('\\').pop();
          $span.text(fileName ? fileName : originalLabel);
        });
      });
    },

    initFeatures: function() {
      var animated = $('.feature__number').each(function () {
        var $value = $(this);
        $value.prop('animateOrig', $value.text());
        var match = $value.text().match(/^(\D*)(\d+)(.*)$/);
        $value.prop('animatePrefix', match[1]);
        $value.prop('animateTo', match[2].replace(/\D/g, ''));
        $value.prop('animateSuffix', match[3]);
        $value.prop('top', $value.offset().top);
        $value.prop('bottom', $value.offset().top + $value.outerHeight());
        // $value.css({width: $value.width() * 1.05, textAlign: 'right' });
      }).toArray();

      $(window).on('scroll resize', function () {
        var viewport_top = $(window).scrollTop();
        var viewport_bottom = viewport_top + $(window).height();

        for (var i = animated.length - 1; i >= 0; i--) {
          var $value = $(animated[i]);
          if ($value.prop('top') <= viewport_bottom && $value.prop('bottom') >= viewport_top) {
            animated.splice(i, 1);

            $value.velocity({
              tween: $value.prop('animateTo')
            }, {
                duration: 1500,
                easing: 'ease',
                progress: progress,
                complete: complete,
              });
          }
        }

        function progress(el, c, r, s, tweenValue) {
          var $element = $(el);
          $element.text($element.prop('animatePrefix') + Math.ceil(tweenValue) + $element.prop('animateSuffix'));
        }

        function complete(el) {
          var $element = $(el);
          $element.text($element.prop('animateOrig'));
        }
      });
    },

    maps: {
      init: function() {
        if(tarteaucitron.launch.googlemaps) {
          if ($('.contact__map').length) App.maps.initContactMap();
        }
      },

      fitBounds: function (map, markers) {
        if (markers.length) {
          var bounds = new google.maps.LatLngBounds();

          for (var i = 0; i < markers.length; i++) {
            bounds.extend(markers[i].getPosition());
          }

          // Don't zoom in too far on only one marker : https://stackoverflow.com/questions/3334729/google-maps-v3-fitbounds-zoom-too-close-for-single-marker
          if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
            var extendPoint1 = new google.maps.LatLng(bounds.getNorthEast().lat() + 0.8, bounds.getNorthEast().lng() + 0.8);
            var extendPoint2 = new google.maps.LatLng(bounds.getNorthEast().lat() - 0.8, bounds.getNorthEast().lng() - 0.8);
            bounds.extend(extendPoint1);
            bounds.extend(extendPoint2);
          }

          map.fitBounds(bounds);
          // center = bounds.getCenter();
        }
      },

      initContactMap: function () {
        var $map = $('#map');

        // Init map
        var map = new google.maps.Map($map.get(0), {
          center: new google.maps.LatLng(48.1120635, -1.6792663),
          zoom: 15,
          scrollwheel: false,
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER
          },
          styles: App.config.mapStyles
        });
        var infoWindow = new google.maps.InfoWindow();

        // Init markers
        var markers = [];
        createMarker();

        // App.maps.fitBounds(map, markers);

        google.maps.event.addListener(map, 'click', function (event) {
          overlay.hide();
        });

        function createMarker() {
          var latlng = new google.maps.LatLng(48.1120635, -1.6792663);

          var icon = {
            path: "M27.429 16c0 7.571-6.143 13.714-13.714 13.714s-13.714-6.143-13.714-13.714 6.143-13.714 13.714-13.714 13.714 6.143 13.714 13.714z",
            fillColor: '#5587f7',
            fillOpacity: 0.8,
            anchor: new google.maps.Point(16, 16),
            strokeWeight: 0,
            scale: 1
          };

          var marker = new google.maps.Marker({
            map: map,
            position: latlng,
            icon: icon
          });

          google.maps.event.addListener(marker, 'click', function () {
            infoWindow.setContent($('.coords__address').html());
            infoWindow.open(map, marker);
          });

          markers.push(marker);
        }

      }
    },

    gtag: function (action, category, label, value) {
      if (window.gtag !== undefined) {
        window.gtag('event', action, {
          'event_category': category,
          'event_label': label,
          'value': value
        });
      }
    },

    gtag_view: function (path) {
      if (window.gtag !== undefined) {
        window.gtag('event', 'form', {
          'event_category': 'form',
          'event_label': 'form',
          'value': path
        });
        window.gtag('config', tarteaucitron.user.gtagUa, {
          'page_path': path
        });
      }
    }

  };
  // ---------- APP END

  // Init app
  $(document).ready(App.initOnce);  // Barba takes care of initPage
  $(window).on('load', App.load);

})(jQuery);

window.App.config.mapStyles = [
  {
    "featureType": "administrative",
    "elementType": "all",
    "stylers": [
      {
        "saturation": "-100"
      },
      {
        "visibility": "simplified"
      },
      {
        "lightness": "-25"
      }
    ]
  },
  {
    "featureType": "administrative.province",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "landscape",
    "elementType": "all",
    "stylers": [
      {
        "saturation": -100
      },
      {
        "lightness": 65
      },
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "all",
    "stylers": [
      {
        "saturation": -100
      },
      {
        "lightness": "50"
      },
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "all",
    "stylers": [
      {
        "saturation": "-100"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "all",
    "stylers": [
      {
        "lightness": "30"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "all",
    "stylers": [
      {
        "lightness": "40"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "all",
    "stylers": [
      {
        "saturation": -100
      },
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "hue": "#ffff00"
      },
      {
        "lightness": "-10"
      },
      {
        "saturation": -97
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels",
    "stylers": [
      {
        "lightness": -25
      },
      {
        "saturation": -100
      }
    ]
  }
];
